<template>
	<chart
		:title="title"
		:last-update="lastUpdate"
		:options="options"
		:is-loading="isLoading"
		:load-fail="loadFail"
		@reload="onReload"
	/>
</template>

<script>
	import apis from "@configs/apis";
	import usePieChart from "../../../../components/chart/usePieChart";
	import Chart from "@common-components/chart/Chart.vue";
	import { RANGE_CUSTOM_MONTH, RANGE_LAST_MONTH } from "@configs/filter";
	import { ref, watch, onMounted } from "@vue/composition-api";
	import store from "@/store";

	export default {
		components: {
			Chart,
		},
		setup() {
			const baseTitle = "GP";
			const title = ref(baseTitle);

			const setTitle = () => {
				if (
					store.getters["fulfillment/GET_QUERY_PARAMS_WITH_LAST_MONTH"]
						.range == RANGE_LAST_MONTH
				) {
					title.value = baseTitle + " (Last Month)";
				} else {
					title.value = baseTitle;
				}
			};

			watch(
				() => store.getters["fulfillment/GET_QUERY_PARAMS_WITH_LAST_MONTH"],
				function () {
					setTitle();
				}
			);

			onMounted(() => {
				setTitle();
			});


			return {
				...usePieChart({
					chartApi: apis.faGPClientChart,
					queryMonthOffset: -1,
					queryParams: "fulfillment/GET_QUERY_PARAMS_WITH_LAST_MONTH",
				}),
				title,
			};
		},
	};
</script>
