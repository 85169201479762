import { fetcher, FetchMethod } from "@/libs/axios";
import { ref, onMounted, watch } from "@vue/composition-api";
import {
    getZonedDatesFromFullDates,
    getZonedYearMonthFromFullDate,
    getZonedYearFromFullDate,
    getLocalDateTimeString,
} from "@/utils/date-time-helper";
import { X_AXIS_MODE_DATE, X_AXIS_MODE_MONTH } from "@configs/filter"; 
import { chartNumberFormatter } from '@/utils/ui-helper';
import store from "@/store";

export default function ({ queryParams, chartApi, customFormatter, yAxisLabel, customYAxis, customSeries, customLegend }) {
    const isLoading = ref(false);
    const loadFail = ref(false);
    const lastUpdate = ref(null);
    const options = ref({});

    onMounted(() => {
        fetchChart();
    });

    watch(
        () => store.getters[queryParams],
        function () {
            fetchChart();
        }
    );

    function onReload() {
        fetchChart();
    }

    function setOptions(response, queryParams) {
        let currentXAxis = Object.keys(response.bar);
        let previousXAxis = Object.keys(response.line);

        let xAxisData;
        let currentLabel;
        let previousLabel;

        if (queryParams.xAxisMode == X_AXIS_MODE_DATE) {
            currentLabel = getZonedYearMonthFromFullDate(
                queryParams.currentStartDate
            );
            previousLabel = getZonedYearMonthFromFullDate(
                queryParams.previousStartDate
            );
            xAxisData = getZonedDatesFromFullDates(
                currentXAxis.length > previousXAxis.length
                    ? currentXAxis
                    : previousXAxis
            );
        } else if (queryParams.xAxisMode == X_AXIS_MODE_MONTH) {
            // X_AXIS_MODE_MONTH
            currentLabel = getZonedYearFromFullDate(
                queryParams.currentStartDate
            );
            previousLabel = getZonedYearFromFullDate(
                queryParams.previousStartDate
            );
            xAxisData =
                currentXAxis.length > previousXAxis.length
                    ? currentXAxis
                    : previousXAxis;
        } else {
            currentLabel =
                getZonedYearFromFullDate(queryParams.currentStartDate) +
                "-" +
                getZonedYearFromFullDate(queryParams.currentEndDate);
            previousLabel =
                getZonedYearFromFullDate(queryParams.previousStartDate) +
                "-" +
                getZonedYearFromFullDate(queryParams.previousEndDate);

            xAxisData = Object.keys(response.line);
        }

        let series = [
            {
                name: currentLabel,
                data: Object.values(response.bar).map((val) => Math.round(val)),
                type: "bar",
            },
            {
                name: previousLabel,
                data: Object.values(response.line).map((val) => Math.round(val)),
                type: "line",
            },
        ];

        let yAxis = [{
            type: "value",
            axisLabel: {
                formatter: customFormatter || chartNumberFormatter
            },
            name: yAxisLabel,
            nameLocation: 'middle',
            nameGap: 50
        }];

        let legend = {
            data: [previousLabel, currentLabel],
        };

        options.value = {
            legend: customLegend != null ? customLegend(legend) : legend,
            xAxis: {
                type: "category",
                data: xAxisData,
            },
            yAxis: customYAxis != null ? customYAxis(yAxis) : yAxis,
            series: customSeries != null ? customSeries(series) : series,
        };
    }

    async function fetchChart() {
        isLoading.value = true;
        loadFail.value = false;

        let queryParamsObj = store.getters[queryParams];

        try {
            const response = await fetcher(
                chartApi,
                FetchMethod.GET,
                queryParamsObj
            );

            setOptions(response, queryParamsObj);

            lastUpdate.value = getLocalDateTimeString()
        } catch (e) {
            console.log("Error", e);
            loadFail.value = true;
        } finally {
            isLoading.value = false;
        }
    }

    return {
        isLoading,
        loadFail,
        lastUpdate,
        options,
        onReload,
    };
}
