<template>
	<chart
		title="Cycle Count"
		:last-update="lastUpdate"
		:options="options"
		:is-loading="isLoading"
		:load-fail="loadFail"
		@reload="onReload"
	/>
</template>

<script>
	import { chartPercentageFormatter } from '@/utils/ui-helper';
	import apis from "@configs/apis";
	import useLineBarChart from '../../../../components/chart/useLineBarChart';
	import Chart from "@common-components/chart/Chart.vue";
	import store from "@/store";

	export default {
		components: {
			Chart,
		},
		setup() {
			return useLineBarChart({ queryParams: "fulfillment/GET_QUERY_PARAMS", chartApi: apis.cycleCountChart, customFormatter: chartPercentageFormatter, yAxisLabel: "Scanned / Inventory" });
		},
	};
</script>
