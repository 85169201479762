<template>
	<el-select
		clearable
		v-model="wrapperSelected"
		multiple
		collapse-tags
		filterable
		:placeholder="placeholder"
	>
		<el-option
			v-for="item in wrapperOptions"
			:key="item.value"
			:label="item.text"
			:value="item.value"
		>
		</el-option>
	</el-select>
</template>

<script>
	// [{ text, value }]
	import { ref, computed, onMounted, watch } from "@vue/composition-api";
	import _ from "lodash";

	export default {
		props: {
			selected: {
				type: Array,
				required: true,
			},
			placeholder: {
				type: String,
			},
			options: {
				type: Array,
				required: true,
			},
		},
		setup(props, ctx) {
			const selectedAll = ref(true);
			const timer = ref(null);

            const wrapperOptions = computed(() => {
				return [{ text: "All", value: "KEC_ALL" }, ...props.options];
			});

            watch(
                () => props.options,
                () => {
                    ctx.emit("update:selected", ['KEC_ALL', ...props.options.map(item => item.value)]);
                }
            )

			const wrapperSelected = computed({
				get() {
                    return props.selected;
				},
				set(arr) {
					let result = arr;

                    // handle select all / unselect all
					if (_.findIndex(arr, (val) => val == "KEC_ALL") > -1) {
						// add
						if (!selectedAll.value) {
							selectedAll.value = true;
							result = wrapperOptions.value.map((item) => item.value);
						}
					} else {
						// remove
						if (selectedAll.value) {
							selectedAll.value = false;
							result = [];
						}
					}


                    // handle select / unselect other, push / remove back all
					let tmp = [...result];
					const allIdx = _.findIndex(result, (val) => val == "KEC_ALL");
					if (allIdx > -1) {
						tmp.splice(allIdx, 1);
					}
					if (props.options.length == tmp.length) {
                        tmp = ['KEC_ALL', ...tmp];
                        selectedAll.value = true;
					} else {
                        selectedAll.value = false;
                    }
                    result = tmp;

					ctx.emit("update:selected", result);
				},
			});

			return {
				wrapperSelected,
				wrapperOptions,
				selectedAll,
			};
		},
	};
</script>

<style scoped>
</style>