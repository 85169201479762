<template>
	<div id="root">
		<fulfillment-filter />

		<data-card-list />

		<div class="pb-2" style="flex: 1">
			<VueSlickCarousel v-bind="settings" class="remaining-area pt-1">
				<div
					class="container-fluid p-0 pl-1 pr-1 h-100"
					v-for="(page, pageIdx) in chartPages"
					:key="pageIdx"
				>
					<div class="row h-100 align-items-stretch">
						<div
							:class="[
								page.length == 1 ? 'col-12' : 'col-6',
								'p-1',
								page.length == 1 ? 'h-100' : 'h-50',
							]"
							v-for="chart in page"
							:key="chart.name"
						>
							<component :is="chart.value" />
						</div>
					</div>
				</div>
			</VueSlickCarousel>
		</div>
	</div>
</template>

<script>
	import VueSlickCarousel from "vue-slick-carousel";
	import { computed, onMounted, onUnmounted, ref } from "@vue/composition-api";
	import store from "@/store";

	import FulfillmentFilter from "./components/FulfillmentFilter.vue";
	// import DataCardList from "./components/data-cards/management/DataCardList.vue";

	// Accrual
	import DataCardList from "./components/data-cards/accrual/DataCardList.vue";
	import RevenueLineBar from "./components/charts/accrual/RevenueLineBar.vue";
	import RevenuePie from "./components/charts/accrual/RevenuePie.vue";
	import GPLineBar from "./components/charts/accrual/GPLineBar.vue";
	import FTEAndPTEHeadCount from "./components/charts/accrual/FTEAndPTEHeadCount.vue";
	import FTEAndPTECost from "./components/charts/accrual/FTEAndPTECost.vue";
	import GPPie from "./components/charts/accrual/GPPie.vue";
	import VASPie from "./components/charts/accrual/VASPie.vue";

	// import RevenueLineBar from "./components/charts/management/RevenueLineBar.vue";
	// import GPLineBar from "./components/charts/management/GPLineBar.vue";
	// import FTEAndPTEHeadCount from "./components/charts/management/FTEAndPTEHeadCount.vue";
	// import FTEAndPTECost from "./components/charts/management/FTEAndPTECost.vue";
	import InboundVolume from "./components/charts/management/InboundVolume.vue";
	import OutboundVolume from "./components/charts/management/OutboundVolume.vue";
	import InventoryLineBar from "./components/charts/management/InventoryLineBar.vue";
	import PickingAccuracy from "./components/charts/management/PickingAccuracy.vue";
	import CycleCount from "./components/charts/management/CycleCount.vue";
	import Relocation from "./components/charts/management/Relocation.vue";
	import Shortage from "./components/charts/management/Shortage.vue";
	import Damage from "./components/charts/management/Damage.vue";
	import StorageSqFt from "./components/charts/management/StorageSqFt.vue";
	import WarehouseUsage from "./components/charts/management/WarehouseUsage.vue";
	// import RevenuePie from "./components/charts/management/RevenuePie.vue";
	// import GPPie from "./components/charts/management/GPPie.vue";
	import InventoryPie from "./components/charts/management/InventoryPie.vue";
	// import VASPie from "./components/charts/management/VASPie.vue";

	export default {
		components: {
			VueSlickCarousel,

			FulfillmentFilter,
			DataCardList,

			RevenueLineBar,
			GPLineBar,
			FTEAndPTEHeadCount,
			FTEAndPTECost,
			InboundVolume,
			OutboundVolume,
			InventoryLineBar,
			PickingAccuracy,
			CycleCount,
			Relocation,
			Shortage,
			Damage,
			StorageSqFt,
			WarehouseUsage,
			RevenuePie,
			GPPie,
			InventoryPie,
			VASPie,
		},
		setup() {
			const chartPages = computed(() => {
				let pages = [];
				let page = [];

				for (
					let i = 0;
					i < store.state.fulfillment.tool.displayCharts.length;
					i++
				) {
					const chart = store.state.fulfillment.tool.displayCharts[i];
					if (chart.singlePage) {
						if (page.length > 0) {
							pages.push(page);
						}
						pages.push([chart]);
						page = [];
					} else {
						page.push(chart);
						if (page.length >= 4) {
							pages.push(page);
							page = [];
						}
					}
				}

				if (page.length > 0) {
					pages.push(page);
				}
				return pages;
			});

			return {
				chartPages,
				settings: {
					lazyLoad: "ondemand",
					arrows: true,
					dots: true,
					centerMode: true,
					centerPadding: "50px",
					focusOnSelect: true,
					infinite: true,
					slidesToShow: 1,
					speed: 500,
				},
			};
		},
	};
</script>

<style scoped>
	#root {
		display: flex;
		flex-direction: column;
		height: 100%;
	}
	.remaining-area {
		flex: 1;
	}
	.padding-chart {
		padding: 0.5rem;
	}
</style>
