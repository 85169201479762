<template>
	<chart
		title="Inventory"
		:last-update="lastUpdate"
		:options="options"
		:is-loading="isLoading"
		:load-fail="loadFail"
		@reload="onReload"
	/>
</template>

<script>
	import apis from "@configs/apis";
	import usePieChart from '../../../../components/chart/usePieChart';
	import Chart from "@common-components/chart/Chart.vue";
	export default {
		components: {
			Chart,
		},
		setup() {
			return usePieChart({ chartApi: apis.inventoryClientChart, queryParams: "fulfillment/GET_QUERY_PARAMS" });
		},
	};
</script>
