<template>
	<chart
		:title="title"
		:last-update="lastUpdate"
		:options="options"
		:is-loading="isLoading"
		:load-fail="loadFail"
		@reload="onReload"
	/>
</template>

<script>
	import { chartAmountFormatter } from "@/utils/ui-helper";
	import apis from "@configs/apis";
	import useLineLineBarChart from "../../../../components/chart/useLineLineBarChart";
	import Chart from "@common-components/chart/Chart.vue";
	import { RANGE_CUSTOM_MONTH, RANGE_LAST_MONTH } from "@configs/filter";
	import { ref, watch } from "@vue/composition-api";
	import store from "@/store";

	export default {
		components: {
			Chart,
		},
		setup() {
			const baseTitle = "FTE & PTE Headcount";
			const title = ref(baseTitle);

			watch(
				() => store.getters["fulfillment/GET_QUERY_PARAMS_WITH_LAST_MONTH"],
				function () {
					if(store.getters["fulfillment/GET_QUERY_PARAMS_WITH_LAST_MONTH"].range == RANGE_LAST_MONTH) {
						title.value = baseTitle + " (Last Month)";
					} else {
						title.value = baseTitle;
					}
				}
			);

			return {
				...useLineLineBarChart({
					queryParams: "fulfillment/GET_QUERY_PARAMS_WITH_LAST_MONTH",
					chartApi: apis.staffHeadcountChart,
					yAxisLabel: "Qty",
					yAxisLabel2: "Accumulate",
				}),
				title
			};
		},
	};
</script>
