import store from "@/store";
import { fetcher, FetchMethod } from "@/libs/axios";
import { ref, onMounted, watch } from "@vue/composition-api";
import { getLocalDateTimeString } from "@/utils/date-time-helper";


export default function (api, params) {
    const isLoading = ref(false);
    const loadFail = ref(false);
    const lastUpdate = ref(null);
    const value = ref(null);

    onMounted(() => {
        fetchData();
    });

    watch(
        () => store.getters[params],
        function () {
            fetchData();
        }
    );

    function onReload() {
        fetchData();
    }

    async function fetchData() {
        isLoading.value = true;
        loadFail.value = false;

        try {
            const queryParams = store.getters[params];

            const response = await fetcher(
                api,
                FetchMethod.GET,
                queryParams
            );

            value.value = response;

            lastUpdate.value = getLocalDateTimeString()
        } catch (e) {
            loadFail.value = true;
        } finally {
            isLoading.value = false;
        }
    }

    return {
        isLoading,
        loadFail,
        lastUpdate,
        value,
        onReload,
    };
}