<template>
	<data-card
		:customClass="'mb-0'"
		:background-color="backgroundColor"
		:title="title"
		:type="type"
		:value="wrapperValue"
		:is-loading="isLoading"
	/>
</template>

<script>
	import DataCard from "@common-components/data-card/DataCard.vue";
	import DataCardTypes from "@common-components/data-card/data-card-types";
	import Colors from "@configs/colors";
	import { ref, onMounted, computed } from "@vue/composition-api";

	export default {
		components: {
			DataCard,
		},
		props: {
			isLoading: {
				type: Boolean,
				required: true,
			},
			loadFail: {
				type: Boolean,
				required: true,
			},
			lastUpdate: {
				type: String,
			},
			value: {
				type: Object,
			},
			onReload: {
				type: Function,
			},
		},
		setup(props) {
			const wrapperValue = computed(() => {
				if(props.value == null) return 0;
				else return props.value.warehouseCapacity;
			})

			return {
				backgroundColor: Colors.Amaranth,
				title: "WH Capacity",
				type: DataCardTypes.Percentage,
				wrapperValue,
			};
		},
	};
</script>

<style scoped>
</style>