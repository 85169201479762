<template>
	<chart
		supportPieDetail
		title="Damage"
		:last-update="lastUpdate"
		:options="options"
		:is-loading="isLoading"
		:load-fail="loadFail"
		@click="onClick"
		@reload="onReload"
	/>
</template>

<script>
	import apis from "@configs/apis";
	import useLineLineBarChart from "../../../../components/chart/useLineLineBarChart";
	import Chart from "@common-components/chart/Chart.vue";
	import store from "@/store";

	export default {
		components: {
			Chart,
		},
		setup() {
			return useLineLineBarChart({
				detailApi: apis.damageClientChart,
				detailTitle: "Damage",

				queryParams: "fulfillment/GET_QUERY_PARAMS",
				chartApi: apis.damageChart,
				yAxisLabel: "PCs",
				yAxisLabel2: "Accumulate"
			});
		},
	};
</script>
