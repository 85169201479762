<template>
	<chart
		title="Sq.Ft Usage"
		:last-update="lastUpdate"
		:options="options"
		:is-loading="isLoading"
		:load-fail="loadFail"
		@reload="onReload"
	/>
</template>

<script>
	import { chartAmountFormatter } from "@/utils/ui-helper";
	import apis from "@configs/apis";
	import Chart from "@common-components/chart/Chart.vue";

	import store from "@/store";
	import { fetcher, FetchMethod } from "@/libs/axios";
	import { ref, onMounted, watch } from "@vue/composition-api";
	import {
		getZonedDatesFromFullDates,
		getZonedYearMonthFromFullDate,
		getZonedYearFromFullDate,
		getLocalDateTimeString,
	} from "@/utils/date-time-helper";
	import { X_AXIS_MODE_DATE, X_AXIS_MODE_MONTH } from "@configs/filter";
	import { chartNumberFormatter } from "@/utils/ui-helper";

	export default {
		components: {
			Chart,
		},
		setup() {
			const isLoading = ref(false);
			const loadFail = ref(false);
			const lastUpdate = ref(null);
			const options = ref({});

			onMounted(() => {
				fetchChart();
			});

			watch(
				() => store.getters["fulfillment/GET_QUERY_PARAMS"],
				function () {
					fetchChart();
				}
			);

			function onReload() {
				fetchChart();
			}

			function setOptions(response, queryParams) {
				let xAxisData;
				if (queryParams.xAxisMode == X_AXIS_MODE_DATE) {
					xAxisData = getZonedDatesFromFullDates(Object.keys(response));
				} else {
					// X_AXIS_MODE_MONTH
					xAxisData = Object.keys(response);
				}

				let clientDataMap = {};
				Object.keys(response).forEach((key) => {
					const dayData = response[key];
					Object.keys(dayData).forEach((clientName) => {
						if (clientDataMap[clientName] == null) {
							clientDataMap[clientName] = [];
						}
						clientDataMap[clientName].push(dayData[clientName]);
					});
				});

				options.value = {
					legend: {
						data: Object.keys(clientDataMap),
					},
					xAxis: {
						type: "category",
						data: xAxisData,
					},
					yAxis: {
						type: "value",
						axisLabel: {
							formatter: chartNumberFormatter,
						},
					},
					series: Object.keys(clientDataMap).map((key) => {
						return {
							name: key,
							data: clientDataMap[key],
							type: "line",
						};
					}),
				};
			}

			async function fetchChart() {
				isLoading.value = true;
				loadFail.value = false;

				try {
					const queryParams =
						store.getters["fulfillment/GET_QUERY_PARAMS"];

					const response = await fetcher(
						apis.storageSqFt,
						FetchMethod.GET,
						queryParams
					);

					setOptions(response, queryParams);

					lastUpdate.value = getLocalDateTimeString();
				} catch (e) {
					console.log(e);
					loadFail.value = true;
				} finally {
					isLoading.value = false;
				}
			}

			return {
				isLoading,
				loadFail,
				lastUpdate,
				options,
				onReload,
			};
		},
	};
</script>